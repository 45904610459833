import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import Tooltip from '@mui/material/Tooltip';

import { getMediaByID } from '../../../Services/Shared/SharedServices';

export const LinkBox = (props: any) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ media, setMedia ] = useState<any>();
    
	const data = props.link.acf;

	useEffect(()=> {
		let promises = [];

		if( props.link.featured_media != 0 ) {
			promises.push(
				getMediaByID(props.link.featured_media)
					.then((response: any) => {
							setMedia(response.data)
						})
				)
		}

		Promise.all(promises)
				.then(() => setIsLoading(false))
				.catch((error) => {
				if (error.status.code === 404) {
						setIsLoading(false);
				} else {
						toast.error("Ha habido un error");
				}
		});

	}, [isLoading])

	
	return (
		<div className={props.className}>
			<a href={data.url}>
				{ media ? <img src={media?.source_url} alt="" /> : <></>}
				{ props.tooltip 
					? <Tooltip title={data.nombre} placement="top">
							<p>{data.nombre}</p>
						</Tooltip>
					:  <p>{data.nombre}</p> }
			</a>
		</div>
	)

}

