import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useHistory, Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';


import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';


import { Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel} from '@mui/lab';

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";

import { textShortener } from '../../../Utils/UtilFunctions';

import { getPosts, getPostCategories, getNewcomers, getAlerts } from '../../../Services/News/NewsServices';
import { getMediaByID, getAllMedia } from '../../../Services/Shared/SharedServices';

import './News.css';

export const News = () => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ media, setMedia ] = useState<any[]>([]);
  const [ news, setNews ] = useState<any[]>([]);
  const [ categories, setCategories ] = useState<any[]>([]);
  
  const [ featuresNews, setFeaturesNews ] = useState<any[]>([]);
  const [ aedasNews, setAedasNews ] = useState<any[]>([]);
  const [ esg, setEsg ] = useState(null);
  const [ promotionsNews, setPromotionsNews ] = useState<any[]>([]); 
  const [ alerts, setAlerts ] = useState<any[]>([]); 
  const [ newcomers, setNewcomers ] = useState<any[]>([]); 
  
  const history = useHistory();
  
  const categoryDictionary: any = {
    'Uncategorised': 1,
    'DESTACADAS':3,
    'ESG': 4,
    'PROMOCIONES': 5,
    'AEDAS HOMES': 6
  };
  
  useEffect(() => {

    let promises = [];

    promises.push(
      getPosts()
        .then((response: any) => {
          setNews(response.data);
      })
    );

    promises.push(
      getAllMedia()
        .then((response: any) => {
            setMedia(response.data);
        })
    )

    // promises.push(
    //   getPostCategories()
  //       .then((response: any) => {
  //           setCategories(response.data);
  //     })
    // )

    promises.push(
      getAlerts()
        .then((response: any) => {
          setAlerts(response.data);
        })
    )

    promises.push(
      getNewcomers()
        .then((response: any) => {
          setNewcomers(response.data);
        })
    )

    Promise.all(promises)
        .then(() => setIsLoading(false))
        .catch((error) => {
        if (error.status.code === 404) {
            setIsLoading(false);
        } else {
            toast.error("Ha habido un error");
        }
    });
  }, [isLoading])

  useEffect(() => {

    //ToDo create an dictionary with query results to replace it

    // categories.map((item:any) => {    
    //   console.log(item);
    //   categoryDictionary.push({
    //     id: item.id,
    //     name: item.name 
    //   })
    // })

    setFeaturesNews(
      news.filter( (news:any) => news?.categories.includes(categoryDictionary['DESTACADAS']))
    )

    setPromotionsNews(
      news.filter( (news:any) => news?.categories.includes(categoryDictionary['PROMOCIONES']))
    )

    setEsg(
      news.filter( (news:any) => news?.categories.includes(categoryDictionary['ESG']))[0]
    )

    setAedasNews(
      news.filter( (news:any) => news?.categories.includes(categoryDictionary['AEDAS HOMES']))
    )

  }, [news])

  const handleRoute = (e: any) => {    

    const targetText = e.target.textContent;

    if (e.target.nodeName === 'BUTTON') {
      const buttonCSSClasses = e.target.className;
  
      const isArchive = buttonCSSClasses.includes('archive') ? true : buttonCSSClasses.includes('single') ? false : undefined;
      const category_id = buttonCSSClasses.includes('category') ? buttonCSSClasses.split(' ').find((item: any) => item.startsWith('category')).slice(-1) : undefined;
  
      const path = targetText === 'PROMOCIONES' ? 'promociones' : targetText === 'AEDAS HOMES' ? 'aedas-homes' : 'destacadas' ;
      let categoryName = '';
      const id = e.target.id;
  
      for (const category in categoryDictionary ) {
        if (categoryDictionary[category] == category_id) {
          categoryName = category.toLocaleLowerCase().replace(' ', '-');
        }  
      }
      
      isArchive ? history.push(`/noticias/${path}`) : history.push(`/noticias/${categoryName}/${id}`);
      
    } else if (e.target.nodeName === 'H4') {

      const path = targetText.includes('Alertas') ? 'alertas' : targetText.includes('Incorporaciones') ? 'incorporaciones' : undefined;

      if ( path ) {
        history.push(`/${path}`);
      }
    }
  }


  return (
    <>
      {isLoading ? ( <AedasLoading /> ) : ( 
          <div className='news-container'>
            <FeatureNews news={ featuresNews } media={media} handleRoute={handleRoute} category={categoryDictionary['DESTACADAS']}/>
            
            <div className="section-box news-by-categories">
              { esg ? 
                  <div className='category-news'>
                    <Button 
                      className='button' 
                      variant="contained" 
                      startIcon={<ArrowCircleDownOutlinedIcon />}
                      href={esg['acf']['link']}
                    >
                      ESG
                    </Button>  
                    
                    <div className='white-box'>
                      <div className="title-container">
                        <h5>{ esg['title']['rendered'] }</h5>
                      </div>
                      <p>{ esg['acf']['subtitulo'] }</p>
                    </div>                 
                  </div>
              : <></>}

              <div className='category-news'>
                <Button 
                  className='button archive promotions' 
                  variant="contained" 
                  onClick={ (e:any) => handleRoute(e)}
                  >
                    PROMOCIONES
                </Button>  
                <BlockNews news={ promotionsNews } handleRoute={handleRoute} category={categoryDictionary['PROMOCIONES']} />                                 
              </div>

              <div className='category-news'>
                <Button 
                  className='button archive aedas-news' 
                  variant="contained"
                  onClick={ (e:any) => handleRoute(e)}
                  >
                    AEDAS HOMES
                </Button>  
                <BlockNews news={ aedasNews } handleRoute={handleRoute} category={categoryDictionary['AEDAS HOMES']}/>                                 
              </div>  
            </div>

            <div className="two-columns">
              <ExtraSection name={'Alertas'} data={ alerts } media={media} handleRoute={handleRoute}/>
              <ExtraSection name={'Incorporaciones y nombramientos'} data={ newcomers } media={media} sectionClassName={'people'} handleRoute={handleRoute}/>
            </div>
          </div>     
      )}
    </>
  )
}



const FeatureNews = (props: any) => {
  const [ value, setValue ] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className='featured-news-container'>
      <TabContext value={ value } > 
        { props.news.map( (news: any, i: number) => 
          {
            let title = textShortener(news['title']['rendered'], 13);
            let imageURL = props.media.filter((media: any) => media.id === news.featured_media);

            return ( 
              <TabPanel value={i.toString()} key={i}>
                <div className='section-box featured-news'>
                  <img src={ imageURL[0]['source_url'] } alt={ news.title.rendered } />
                  <div className='news-title'>
                    {/* <p>El reto solidario de <strong>AEDAS Homes</strong> recauda 25.000€ </p> */}
                    <p> { title }</p>
                    <Button 
                      className={`button single category${props.category}`} 
                      id={ news.id }
                      variant="contained"
                      onClick={ (e:any) => props.handleRoute(e)}
                      >
                        ver más
                    </Button>  

                  </div>
                </div>
              </TabPanel>
            )
          }
        )}

        <TabList onChange={handleChange} variant="scrollable">
          { props.news.map( (news: any, i: number) => <Tab value={i.toString()} key={i}/> )}
        </TabList>

      </TabContext>                  
    </div>
  )
}

const BlockNews = (props: any) => {
  const [ value, setValue ] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className='white-box'>
      <TabContext value={ value } > 
        { props.news.map( (news: any, i: number) => 
          {
            let title = textShortener(news['title']['rendered'], 5);

            return ( 
              <TabPanel value={i.toString()} key={i}>
                <div className="title-container">
                  <img src={"/Assets/img/exclamation-logo-grey.png"} alt="" />
                  <Tooltip title={news['title']['rendered']}>
                    <h5>{ title }</h5>
                  </Tooltip>
                </div>
                <p>{ news['acf']['subtitulo'] }</p>

                <Button 
                    className={`load-more single category${props.category}`} 
                    size='small'
                    id={ news.id }
                    variant="text"
                    onClick={ (e:any) => props.handleRoute(e)}
                    >
                      ver más
                </Button>
              </TabPanel>
            )
          }
        )}

        <TabList onChange={handleChange} variant="scrollable">
          { props.news.map( (news: any, i: number) => <Tab value={i.toString()} key={i}/> )}
        </TabList>
      </TabContext>                  
    </div>
  )
}

const ExtraSection = (props: any) => {
  const [ value, setValue ] = useState('1');
  const [ image, setImage ] = useState(null);
  const [ defaultImage, setDefaultImage ] = useState();
  const [ imageID, setImageID ] = useState(100); // defalut image is id = 100
  const [ isLoading, setIsLoading ] = useState(true);

  
	// useEffect(()=> {
	// 	let promises = [];

	// 	if( imageID != 0 ) {
	// 		promises.push(
	// 			getMediaByID(imageID)
	// 				.then((response: any) => {
	// 						setImage(response.data)
	// 					})
	// 			)
	// 	}

  //   promises.push(
  //     getMediaByID(100)
  //       .then((response: any) => {
  //           setDefaultImage(response.data)
  //         })
  //     )

	// 	Promise.all(promises)
	// 			.then(() => setIsLoading(false))
	// 			.catch((error) => {
	// 			if (error.status.code === 404) {
	// 					setIsLoading(false);
	// 			} else {
	// 					toast.error("Ha habido un error");
	// 			}
	// 	});

	// }, [imageID])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    
    <div className="section-box">
      <h4 className='text-center p-4' onClick={(e:any) => props.handleRoute(e)}>{ props.name }</h4>
      <section className={ props.sectionClassName }>

        <TabContext value={ value } > 
          { props.data.map( (item: any, i: number) => 
            {
              let imageURL = props.media.filter((media: any) => media.id === item.featured_media)

              return (
                <TabPanel value={i.toString()} key={i}>
                  <div className='inner-section'>
                    <img src={ imageURL[0]['source_url'] } alt={ item.title.rendered } />
                    <div className='white-box'>
                      <div className="title-container">
                        <h5>{ item.title.rendered }</h5>
                        { item.acf.puesto ? <p> { item.acf.puesto }</p> : <></>}
                      </div>
                      <p> {item.acf.descripcion }</p>
                    </div>
                  </div>
                </TabPanel>
              )
            }       
          )}

        <TabList onChange={handleChange} variant="scrollable">
          { props.data.map( (item: any, i: number) => <Tab value={i.toString()} key={i}/> )}
        </TabList>
      </TabContext>  
    </section>
    </div>
  )
}