import React from 'react';

import { Header } from '../Header/HeaderSingle';
import { SideBar } from '../../Shared/SideBar/SideBar';
import { News } from '../News/News';
import { Footer } from '../Footer/Footer';

import './Home.css';

export const Home = () => {
  return (
    <>
        <Header />
        
        <div className='main-container'>
            <SideBar />
            <News />
        </div>

        <Footer links={true} />
    </>
  )
}
