import * as React from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllMedia = () => {
    // const configSecurity = {
    //   headers: {
    //     Authorization: `Bearer ${
    //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
    //     }`,
    //   },
    // };

    
    return axios
    //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
      .get(process.env.REACT_APP_API_BACKEND + "/media?per_page=100")
      .catch((error) => toast.error("Ha habido un error"));
};

export const getMediaByID = (id: number) => {
    // const configSecurity = {
    //   headers: {
    //     Authorization: `Bearer ${
    //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
    //     }`,
    //   },
    // };

    
    return axios
    //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
      .get(process.env.REACT_APP_API_BACKEND + "/media/" + id)
      .catch((error) => toast.error("Ha habido un error"));
};


//WIP get links by category 

// const getLinksCategories = async () => {
//     // const configSecurity = {
//     //   headers: {
//     //     Authorization: `Bearer ${
//     //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
//     //     }`,
//     //   },
//     // };

   
//     return axios
//     //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
//       .get(process.env.REACT_APP_API_BACKEND + "/category_link")
//       .catch((error) => toast.error("Ha habido un error"));
// };



// export const getLinksByCategory = (category_id: number) => {
//     // const configSecurity = {
//     //   headers: {
//     //     Authorization: `Bearer ${
//     //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
//     //     }`,
//     //   },
//     // };

//     return axios
//       .get(process.env.REACT_APP_API_BACKEND + "/link/" + category_id)
//       .catch((error) => toast.error("Ha habido un error"));
// };