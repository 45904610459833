import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Calendar from 'react-calendar';
import moment from 'moment'; 

import { getAllLinks } from '../../../Services/SideBar/SidebarServices';
import { getAllMedia, getMediaByID } from '../../../Services/Shared/SharedServices';

import { LinkBox } from '../LinkBox/LinkBox';

import 'react-calendar/dist/Calendar.css';
import './SideBar.css';

export const SideBar = () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ links, setLinks ] = useState<any[]>([]);
    const [ apps, setApps ] = useState<any[]>([]);
    // const [ media, setMedia ] = useState<any[]>([]);
    const [ value, onChange ] = useState(new Date());

    useEffect(() => {
        let promises = [];

        promises.push(
            getAllLinks()
                .then((response: any) => {
                   setLinks(response.data);
            })
        );

        // promises.push(
        //     getAllMedia()
        //         .then((response: any) => {
        //             setMedia(response.data);
        //      })
        // )

        Promise.all(promises)
            .then(() => setIsLoading(false))
            .catch((error) => {
            if (error.status.code === 404) {
                setIsLoading(false);
            } else {
                toast.error("Ha habido un error");
            }
        });


    }, [isLoading])

    useEffect(() => {

        //ToDo use name category on filter to avoid show this magic number
        setApps(
            links.filter( (link:any) => link?.acf?.categoria === 8)
        );

		}, [links])


    // const formatDay = (locale, date) => {
    //     formatDate(date, 'd');
    // }
			
    return (
        <>
        {isLoading ? ( <AedasLoading /> ) : ( 
            <div className='sidebar-container'> 
                <div className='calendar-container'>
                    <Calendar 
                        onChange={onChange} 
												value={value} 
												next2Label=''
												prev2Label=''
												/>
                </div>

                <div className='my-apps'>
                    <h4 className='text-center pt-4'>Mis Aplicaciones</h4>
                    <div className='apps-box'>
                        { apps.map( (app: any, i: number ) => <LinkBox link={app} className={'text-center m-2'} key={i}/>)}
                    </div>
                </div>

                <div className='section-box logos'>
                    <p>Adheridos al <strong>pacto mundial de las naciones unidas</strong></p>
                    <div className='images-container'>
                        <img style={{width: '50%'}} src={"/Assets/img/AF_AEDAS_POSITIVO_RGB.png"} alt="" />
                        <img style={{width: '25%'}} src="https://www.undp.org/sites/g/files/zskgke326/files/migration/ec/pnud_ec_rueda-ods.png" alt="" />
                    </div>
                </div>
            </div> 
            )}
        </>
  )
}
