import React, { useEffect, useState } from "react";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";

import { Collapse, List, ListItem } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// import { useLocation } from "react-router-dom";

import "./LisHeader.css";

export default function MainListItems(props: any) {
  const data = props.menu ?? [];

  return (
    <>
      <List component={"nav"}>
        {data
          .filter((item: any) => item.canView === true)
          .map((e: any, i: number) => {
            return (
              <NavItem
                key={i}
                item={e}
                setOpen={props.setOpen}
                open={props.open}
              ></NavItem>
            );
          })}
      </List>
    </>
  );
}

function NavItem(props: any) {
  const { item, setOpen, open } = props;
  const [expandOpen, setExpandOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedChild, setSelectedChild] = useState<any>(null);
  // const location = useLocation();
  
  const handleNavItem = () => {
    if (item.children) {
      setExpandOpen(!expandOpen);
      setOpen(true);
    } else if (item.externalLink) {
      item.onClick=window.open(item.externalLink);
    } else {
      item.onClick(item.localPath);
    }
  };

  useEffect(() => {
    if (!open) {
      setExpandOpen(false);
    }
  }, [open]);

  useEffect(() => {
    if (window.location.pathname === item.localPath) {
      setSelected(true);
    } else if (window.location.pathname !== item.localPath) {
      setSelected(false);
    }
    if (
      item.children &&
      item.children.filter((a: any) => a.localPath === window.location.pathname)
    ) {
      setSelectedChild(
        item.children.find((a: any) => a.localPath === window.location.pathname)
      );
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      selectedChild &&
      selectedChild ===
        item.children.find((c: any) => c.localPath === selectedChild.localPath)
    ) {
      setSelected(true);
    }
  }, [selectedChild]);
  
  const handleNavChildItem = (child: any) => {
    item.onClick(child.localPath);
  };

  return (
    <>
      <ListItemButton
        className={selected && open ? "selectedItem" : ""}
        onClick={() => handleNavItem()}
      >
        <ListItemIcon
          className={
            selected && !open
              ? "selected selectedBar"
              : selected
              ? "selected"
              : ""
          }
          style={{ fill: selected ? "var(--secondary-color)" : "" }}
        >
          <div>
            <div style={{display:"flex", justifyContent:"center"}}>
              {item.icon ?? <DashboardIcon />}
            </div>
            {!open ? (
              <div style={{ width: "50px", paddingTop: "5px",  }}>
                <h1
                  style={{
                    fontSize: "10px",
                    color: selected ? "var(--secondary-color)" : "",
                    textAlign: "center",
                    width: "100%",
                    overflowWrap: "break-word", 
                  }}
                >
                  {item.shortName}
                </h1>
              </div>
            ) : (
              <></>
            )}
          </div>
        </ListItemIcon>

        <ListItemText
          style={{ color: selected ? "var(--secondary-color)" : "" }}
          primary={item.name}
        />

        {item.children ? expandOpen ? <ExpandLess /> : <ExpandMore /> : <></>}
      </ListItemButton>

      <Collapse
        in={expandOpen}
        timeout="auto"
        unmountOnExit
        className="navNested"
      >
        {item.children ? (
          // <></>
          <List component="div" disablePadding>
            {item.children
              .filter((childFilter: any) => childFilter.canView === true)
              .map((child: any, j: number) => {
                return (
                  <ListItem
                    key={j}
                    button
                    onClick={() => {
                      handleNavChildItem(child);
                    }}
                  >
                    <ListItemIcon
                      className={
                        selectedChild &&
                        selectedChild.localPath === child.localPath
                          ? "selected"
                          : ""
                      }
                      style={{
                        fill:
                          selectedChild &&
                          selectedChild.localPath === child.localPath
                            ? "var(--secondary-color)"
                            : "",
                      }}
                    >
                      {child.icon ?? <DashboardIcon />}
                    </ListItemIcon>

                    <ListItemText
                      style={{
                        color:
                          selectedChild &&
                          selectedChild.localPath === child.localPath
                            ? "var(--secondary-color)"
                            : "",
                      }}
                      primary={child.name}
                    />
                  </ListItem>
                );
              })}
          </List>
        ) : (
          <></>
        )}
      </Collapse>
    </>
  );
}
