import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";


import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import Button from '@mui/material/Button';


import { Header } from '../../Header/HeaderSingle';
import { SideBar } from '../../../Shared/SideBar/SideBar';
import { Footer } from '../../Footer/Footer';

import { getPostByCategory , getNewcomers, getAlerts } from '../../../../Services/News/NewsServices';

import { getMediaByID, getAllMedia } from '../../../../Services/Shared/SharedServices';

import { textShortener } from '../../../../Utils/UtilFunctions';


import './Archive.css'


export const Archive = () => {
  const [ isLoading, setIsLoading ] = useState(true);

  const [ news, setNews ] = useState<any[]>([]);
  // const [ alerts, setAlerts ] = useState<any[]>([]); 
  // const [ newcomers, setNewcomers ] = useState<any[]>([]); 
  const [ media, setMedia ] = useState<any[]>([]);
  const [ archive, setArchive ] = useState('');
  
  
  const params: any = useParams();
  const history = useHistory();
  
  const categoryDictionary: any = {
    // 'Uncategorised': 1,
    'DESTACADAS':3,
    // 'ESG': 4,
    'PROMOCIONES': 5,
    'AEDAS HOMES': 6
  };
  
  const pageTitle = params.category ? 
                    `Noticias ${params.category.replace('-', ' ')}` : 
                    params.type === 'alertas' ? 'Alertas' : 'Incorporaciones y Nombramientos';


  useEffect(() => {

    let promises = [];

    if ( params.category ) {
      setArchive('news');
      const categoryTransform = params.category.replace('-', ' ');

      promises.push(
        getPostByCategory(categoryDictionary[categoryTransform.toUpperCase()])
          .then((response: any) => {
            setNews(response.data);
        })
      );
    } else if ( params.type ) {
      setArchive(params.type);

      const type = params.type;

      if ( type == 'incorporaciones') {

        promises.push(
          getNewcomers()
            .then((response: any) => {
              setNews(response.data);
            })
        )

      } else if (type == 'alertas') {
        promises.push(
          getAlerts()
            .then((response: any) => {
              setNews(response.data);
            })
        )
      }
    }

    promises.push(
      getAllMedia()
        .then((response: any) => {
            setMedia(response.data);
        })
    )

    // promises.push(
    //   getPostCategories()
    //       .then((response: any) => {
    //           setCategories(response.data);
    //     })
    // )
    

    Promise.all(promises)
        .then(() => setIsLoading(false))
        .catch((error) => {
        if (error.status.code === 404) {
            setIsLoading(false);
        } else {
            toast.error("Ha habido un error");
        }
    });
  }, [isLoading])

  
  
  const handleRoute = (e: any) => {   
    const id = e.target.id;  
    history.push(`/noticias/${params.category}/${id}`);
  }


  return (
    <>
      <Header title={ pageTitle }/>
      
      <div className='main-container'>
          <SideBar />

          {isLoading ? ( <AedasLoading /> ) : (
            <div className='news-container archive'>
              { news.map( (news: any, i: number) => 
                <NewsBox 
                  key={i}
                  news={news} 
                  media={media} 
                  type={archive}
                  handleRoute={handleRoute}
                /> ) }             
            </div>
          ) }
      </div>

      <Footer />
    </>
  ) 
}


const NewsBox = (props: any) => {
  
  let imageURL = props.media.filter((media: any) => media.id === props.news.featured_media);
  let subtitle = props.type == 'news' ? 
                  textShortener(props.news['acf']['subtitulo'], 16) : 
                  props.type == 'incorporaciones' ? props.news['acf']['puesto'] : '';
  
  
  
  return (

    <div className='section-box news-list'>
      <div className='image-container'><img src={ imageURL[0]['source_url'] } alt={ props.news.title.rendered } /></div>
      <div className='news-title'>
        <p> <strong>{ props.news['title']['rendered'] }</strong></p>
        <p className={props.type == 'incorporaciones' ? 'rol' : 'subtitle'}>{ subtitle }</p>
        { props.type != 'news' ? 
        <p className='subtitle'>{ props.news['acf']['descripcion'] }</p>
        : <></>}

        { props.type == 'news' ? 
          <Button 
            className={`button`} 
            id={ props.news.id }
            variant="contained"
            onClick={ (e:any) => props.handleRoute(e)}
          >
            ver más
          </Button> 
        : <></>} 

      </div>
    </div>
          
  )
}