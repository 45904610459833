import * as React from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllLinks = () => {
    // const configSecurity = {
    //   headers: {
    //     Authorization: `Bearer ${
    //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
    //     }`,
    //   },
    // };
    
    return axios
    //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
      .get(process.env.REACT_APP_API_BACKEND + "/link?per_page=100")
      .catch((error) => toast.error("Ha habido un error"));
};