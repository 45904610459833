import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { Formik, useFormik } from 'formik';

import { Box, TextField } from "@mui/material";
import Button from "@mui/material/Button";

import AedasLoading from "../../../../Shared/AedasLoading/AedasLoading";

import { publishNewComment, getCommentByPostID } from '../../../../../Services/News/NewsServices';

import './Comments.css';


export const CommentsMain = (props: any) => {

  const [ isLoading, setIsLoading ] = useState(true);
	const [ comments, setComments ] = useState<any[]>([]);
	const [ aprovedComments, setAprovedComments ] = useState<any[]>([]);

	useEffect(() => {

    let promises = [];

    promises.push(
      getCommentByPostID(props.post_id)
        .then((response: any) => {
          setComments(response.data);
      })
    );


    Promise.all(promises)
        .then(() => setIsLoading(false))
        .catch((error) => {
        if (error.status.code === 404) {
            setIsLoading(false);
        } else {
            toast.error("Ha habido un error");
        }
    });

  }, [isLoading])

	useEffect(() => {
		setAprovedComments(comments.filter((comment: any) => comment?.status == "approved"));
	}, [comments])


	const publishComment = (e: any, comment: any, parent: number) => {
		e.preventDefault();
		let author_name = "Default Author";
		const parent_id = e.target.form.id.includes('response') ? parent : 0;

		publishNewComment(props.post_id, author_name, comment, parent_id).then(() => toast.success('Tu comentario fue enviado!'));
		setIsLoading(true);	
	}
 
  return (
    <>
      {isLoading ? ( <AedasLoading /> ) : ( 

				<div className='comment-container'>
					{ aprovedComments.length > 0 
						? <>
								<hr />
								<Comments comments={aprovedComments} post_id={props.post_id} publishComment={publishComment} /> 						
							</>
						: <></>}
					<hr />
					<NewComment publishComment={publishComment}/>
				</div>
			)}
    </>
  )
}


const Comments = (props: any) => {
	
	return (
	<>
			<h4><strong>Comentarios</strong></h4>

			<Box
				component="form"
				id='response-form'	
				sx={{
					"& .MuiTextField-root": { width: "100%", backgroundColor: '#fff', borderRadius:'20px', mt:'0' },
					width: "100%",
					mx: "auto",
					
					my: 4,
					borderRadius:'20px',
					backgroundColor: '#e8eaeb'
				}}
				// onSubmit={(e: any) => props.publishComment(e, response)}
			>


					{ props.comments
						.filter((item: any) => item.parent == 0 ) //just parent comments
						.map( (comment: any, i: number) => {
						
						const content = () => {
							return {__html: `${comment.content.rendered}` }
						}

						return(							
							<div key={i} className='comment-box response '>
								<div className='comment-header'>
									<img className='imgPersonHeaderSmall' src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMTIyNDV8MHwxfHNlYXJjaHwyfHxwZW9wbGV8ZW58MHx8fHwxNjYyMzY1MDM1&ixlib=rb-1.2.1&q=80&w=1080" alt="" />
									<p> <strong>{ comment.author_name } </strong> <span className='comment-content' dangerouslySetInnerHTML={ content() } /> </p>
								</div>
												
								{/* <ResponseComment comment_id={comment.id} key={comment.id} publishComment={props.publishComment}/> */}

							</div>
							
							)
						})}
					

			</Box>
	</>
 )
}

const ResponseComment = (props: any) => {
	const [ response, setResponse ] = useState<string>('');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResponse(event.target.value);
  };
		
	return (
		<>
			<TextField
				id="response"
				label="Responder..."
				// className='response'
				variant="filled"
				multiline={true}
				value={response}
				onChange={handleChange}
			/>

			<Button
				className='response-button' 
				variant="text"
				type='submit'
				onClick={(e: any) => props.publishComment(e, response, props.comment_id)}
				>
				Responder
			</Button>
		</>
	)
}

const NewComment = (props: any) => {
	const [ newComment, setNewComment ] = useState<string>('')

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  };
	
	return (
		<>
			<h4><strong>Nuevo Comentario</strong></h4>
			<Box
				component="form"
				id='new-comment-form'	
				sx={{
					"& .MuiTextField-root": { width: "90%", backgroundColor: '#fff', borderRadius:'20px' },
					width: "100%",
					mx: "auto",
					my: 2,
					borderRadius:'20px',
					backgroundColor: '#e8eaeb'
				}}
			>
				<div className='comment-box new'>
					<img className='imgPersonHeaderSmall' src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMTIyNDV8MHwxfHNlYXJjaHwyfHxwZW9wbGV8ZW58MHx8fHwxNjYyMzY1MDM1&ixlib=rb-1.2.1&q=80&w=1080" alt="" />
					
					<TextField
						id="comment"
						label="Tu comentario"
						className='new-comment'
						variant="filled"
						multiline={true}
						value={newComment}
						onChange={handleChange}
					/>
				</div>

				<Button
					className='button' 
					variant="contained"
					type='submit'
					onClick={(e: any) => props.publishComment(e, newComment)}
				>
					Enviar
				</Button>
			</Box>
		</>
	)
}

