import React, { useEffect, useState } from "react";

import {
  Route,
  useHistory,
  useLocation,
  Redirect,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

import { useIsAuthenticated, useMsal, MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { loginAzure } from "./Services/AuthService/AuthService";

import { CustomNavigationClient } from "./Utils/NavigationClient";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";

import AedasLoading from "./Components/Shared/AedasLoading/AedasLoading";
import Header from "./Components/Shared/Header/Header";
import Login from "./Components/Shared/Login/Login";
import { userLogin } from "./Redux/User/UserAction";

import { Home } from "./Components/Static/Home/Home";
import { Single } from "./Components/Static/News/SingleNews/SingleNews";
import { Archive } from "./Components/Static/News/ArchiveNews/Archive";
import { OrganizationChart } from "./Components/Static/OrganizationChart/OrganizationChart";
import { Education } from "./Components/Static/OrganizationChart/Education";
import { Solicitations } from "./Components/Static/OrganizationChart/Solicitations";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);

  pca.setNavigationClient(navigationClient);

  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <MsalProvider instance={pca}>
        <Router></Router>
      </MsalProvider>
    </>
  );
}

export default App;

const Router = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  // const isLogin = useSelector((state: any) => state.userReducer.isLogin);
  const isLogin = true;
  const user = useSelector((state: any) => state.userReducer.user);
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin) {
      setIsLoading(false);
      history.push(location.pathname);
    }
  }, [isLogin]);

  useEffect(() => {
    // if (isAuthenticated && inProgress === "none") {
    //   const request = {
    //     ...loginRequest,
    //     account: accounts[0],
    //   };
    //   instance
    //     .acquireTokenSilent(request)
    //     .then(async (response) => {
    //       const responseBackend: any = await loginAzure(
    //         response.accessToken
    //       ).catch((error) => console.log("ERROR"));
    //       let position: string = "Por defecto";
    //       if (responseBackend) {
    //         dispatch(
    //           userLogin(responseBackend.data, response.accessToken, position)
    //         );
    //       } else {
    //         toast.error("No tienes permiso");
    //         history.push("/login");
    //         setIsLoading(false);
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // } else if (!isAuthenticated && inProgress === "none") {
    //   instance
    //     .loginRedirect(loginRequest)
    //     .then((response) => console.log("login", response))
    //     .catch((e) => {
    //       console.error(e);
    //     });
    // } else if (inProgress === "none") {
    //   setIsLoading(false);
    // }
  }, [isAuthenticated, inProgress]);

  const menu: any = [
    {
      name: "Home",
      localPath: "/",
      icon: <HomeRoundedIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Home",
      hasPermissions: true,
    },
    {
      name: "Organigrama",
      externalLink: "https://vista-organigrama.aedashomes.com",
      icon: <AssignmentIndRoundedIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Org",
      hasPermissions: true,
    },
    {
      name: "Formación",
      externalLink: "https://formacion-uat.aedashomes.com",
      icon: <AutoStoriesRoundedIcon />,
      onClick: history.push,
      shortName: "Formac.",
      canView: true,
    },
    {
      name: "Solicitudes",
      localPath: "/solicitudes",
      icon: <BorderColorRoundedIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Solic.",
      hasPermissions: true,
    },
  ];

  // if (isLoading) {
  //   return (
  //     <div className="w-100 h-100 d-flex justify-content-center">
  //       <AedasLoading></AedasLoading>
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="row" style={{ height: "100vh", overflowX: "hidden" }}>
        {isLogin ? (
          <Header menu={menu} open={isOpen} setIsOpen={setIsOpen}></Header>
        ) : (
          <></>
        )}

        <div
          style={
            isLogin
              ? {
                  flex: 1,
                  marginLeft: isOpen ? "300px" : "70px",
                  maxWidth: isOpen ? "calc(100% - 300px)" : "calc(100% - 70px)",
                  // overflow: "hidden"
                }
              : {}
          }
          // className="w-100"
        >
          <Switch>
            <Route exact path="/login">
              <Login isLogin={isLogin}></Login>
            </Route>

            <Route exact path="/">
              <Home />
            </Route>

            <PrivateRoute
              auth={isLogin}
              // hasPermissions={validatePermission(
              //   user ? user.permissions : [],
              //   "app_organigrama.rrcc.all"
              // )}
              hasPermissions={true}
              exact
              path="/noticias/:category"
            >
              <Archive />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              // hasPermissions={validatePermission(
              //   user ? user.permissions : [],
              //   "app_organigrama.rrcc.all"
              // )}
              hasPermissions={true}
              exact
              path="/:type"
            >
              <Archive />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              // hasPermissions={validatePermission(
              //   user ? user.permissions : [],
              //   "app_organigrama.rrcc.all"
              // )}
              hasPermissions={true}
              exact
              path="/noticias/:category/:post_id"
            >
              <Single />
            </PrivateRoute>

            {/* <PrivateRoute
            auth={isLogin}
            hasPermissions={true}
            exact
            path="/solicitudes"
          >
            <Solicitations />
          </PrivateRoute> */}
          </Switch>
        </div>
      </div>
    </>
  );
};

const PrivateRoute = (props: any) => {
  if (props.auth) {
    if (props.hasPermissions) {
      return <Route {...props}></Route>;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return <Redirect to="/login" />;
  }
};
