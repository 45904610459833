import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import './Header.css';

export const Header = (props: any) => {
  return (
    <div className="header">
        <h2>{props.title || 'Portal del empleado'}</h2>
        <div className='person-data-box'>
            <img className='imgPersonHeaderSmall' src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMTIyNDV8MHwxfHNlYXJjaHwyfHxwZW9wbGV8ZW58MHx8fHwxNjYyMzY1MDM1&ixlib=rb-1.2.1&q=80&w=1080" alt="" />
            <p className='m-0'>Juana Garcia</p>
            <KeyboardArrowDownIcon />
        </div>
    </div>
  )
}
