import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";
// import '@wordpress/base-styles/index'


import { Header } from '../../Header/HeaderSingle';
import { SideBar } from '../../../Shared/SideBar/SideBar';
import { CommentsMain } from '../SingleNews/Comments/Comments';

import { Footer } from '../../Footer/Footer';
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";

import { getPostsByID } from '../../../../Services/News/NewsServices';
import { getMediaByID, getAllMedia } from '../../../../Services/Shared/SharedServices';

import './SingleNews.css';


export const Single = () => {
  const params: any = useParams();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ news, setNews ] = useState(null);
  const [ media, setMedia ] = useState(null);

  useEffect(() => {

    let promises = [];

    promises.push(
      getPostsByID(params.post_id)
        .then((response: any) => {
          setNews(response.data);
      })
    );
   
    Promise.all(promises)
        .then(() => setIsLoading(false))
        .catch((error) => {
        if (error.status.code === 404) {
            setIsLoading(false);
        } else {
            toast.error("Ha habido un error");
        }
    });


  }, [isLoading])

  useEffect(() => {

    if( news != null ) {

      getMediaByID (news['featured_media'])
        .then((response: any) => {
            setMedia(response.data);
        })
    }

  }, [news])


  return (
    <>
      {isLoading && news == null ? ( <AedasLoading /> ) : ( 
      <>
        <Header title={`Noticias ${params.category.replace('-', ' ')}`}/>
        
        <div className='main-container'>
            <SideBar />

            <section>
              <div className='single-news-container'>
              { media ? ( <img src={ media['source_url'] } alt='' />) : <></> }
              { news ? (
                <>
                  <h4> <strong>{ news['title']['rendered'] }</strong></h4>
                  <p className='subtitle'> { news['acf']['subtitulo'] } </p>
                  <hr />

                  {/* <Content content={ news['content']['rendered'] }/>  */}
                  <div className='content-from-wordpress' dangerouslySetInnerHTML={ {__html: news['content']['rendered'] } } />


                </>
                ) : <></> }
              </div>

              <CommentsMain post_id={params.post_id}/>
            </section>
            {/* <News /> */}
        </div>
    
        <Footer />        
      </>
      )}
    </>
  )
}

// const Content = (props: any) => {
//   const content = () => {
//       return {__html: `${props.content}` }
//   }

//   // console.log(content())

//   return (
//       <div className='content-from-wordpress' dangerouslySetInnerHTML={ content() } />
//   )
// }

