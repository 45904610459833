import * as React from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const getPosts = () => {
    // const configSecurity = {
    //   headers: {
    //     Authorization: `Bearer ${
    //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
    //     }`,
    //   },
    // };
    
    return axios
    //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
      .get(process.env.REACT_APP_API_BACKEND + "/posts?per_page=100")
      .catch((error) => toast.error("Ha habido un error"));
};

export const getPostsByID = (post_id: number) => {
  // const configSecurity = {
  //   headers: {
  //     Authorization: `Bearer ${
  //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
  //     }`,
  //   },
  // };
  
  return axios
  //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
    .get(process.env.REACT_APP_API_BACKEND + "/posts/" + post_id)
    .catch((error) => toast.error("Ha habido un error"));
};


export const getPostCategories = () => {
    // const configSecurity = {
    //   headers: {
    //     Authorization: `Bearer ${
    //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
    //     }`,
    //   },
    // };
    
    return axios
    //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
      .get(process.env.REACT_APP_API_BACKEND + "/categories")
      .catch((error) => toast.error("Ha habido un error"));
};


export const getPostByCategory = (category_id: number) => {
  // const configSecurity = {
  //   headers: {
  //     Authorization: `Bearer ${
  //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
  //     }`,
  //   },
  // };
  
  return axios
  //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
    .get(process.env.REACT_APP_API_BACKEND + "/posts?categories=" + category_id )
    .catch((error) => toast.error("Ha habido un error"));
};


export const getAlerts = () => {
    // const configSecurity = {
    //   headers: {
    //     Authorization: `Bearer ${
    //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
    //     }`,
    //   },
    // };
    
    return axios
    //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
      .get(process.env.REACT_APP_API_BACKEND + "/alertas")
      .catch((error) => toast.error("Ha habido un error"));
};


export const getNewcomers = () => {
    // const configSecurity = {
    //   headers: {
    //     Authorization: `Bearer ${
    //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
    //     }`,
    //   },
    // };
    
    return axios
    //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
      .get(process.env.REACT_APP_API_BACKEND + "/incorporaciones")
      .catch((error) => toast.error("Ha habido un error"));
};

export const getCommentByPostID = (post_id: number) => {
  // const configSecurity = {
  //   headers: {
  //     Authorization: `Bearer ${
  //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
  //     }`,
  //   },
  // };
  
  return axios
  //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
    .get(process.env.REACT_APP_API_BACKEND + "/comments"
    + "?post=" + post_id
    )
    .catch((error) => toast.error("Ha habido un error"));
};



export const publishNewComment = (post_id: number, author_name: string, comment: string, parent: number) => {
  // const configSecurity = {
  //   headers: {
  //     Authorization: `Bearer ${
  //       JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
  //     }`,
  //   },
  // };
  
  return axios
  //   .get(process.env.REACT_APP_API_BACKEND + "/link", configSecurity)
    .post(process.env.REACT_APP_API_BACKEND + "/comments" 
    + "?post=" + post_id
    + "&author_name=" + author_name 
    + "&content=" + comment 
    + "&parent=" + parent
    )
    .catch((error) => toast.error("Ha habido un error"));
};



