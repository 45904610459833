import * as React from "react";
import MainListItems from "../LisHeaderProvaider/listItems";

import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { useHistory } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { userLogout } from "../../../Redux/User/UserAction";
// import { useMsal } from "@azure/msal-react";

import "./Header.css";

function Header(props: any) {
  const { menu, setIsOpen, open, setisLogin } = props;
  // const history = useHistory();
  // const dispatch = useDispatch();
 
  const handleOpenClose = () => {
    setIsOpen(!open);
  };
 
  // const user = useSelector((state: any) => state.userReducer.user);
  // const position = useSelector((state: any) => state.userReducer.position);
  // const { instance, accounts } = useMsal();
  // const handleLogOut = () => {
  //   localStorage.removeItem("aedas_user");
  //   dispatch(userLogout());
  //   instance.logoutRedirect({
  //     account: accounts[0],
  //   });
  // };
  
  return (
    <div
      style={{
        width: open ? "300px" : "70px",
        height: "100%",
        backgroundColor: "white",
        position: "fixed",
        overflow: "hidden",
        boxShadow: "5px 5px 5px 0px rgb(0 0 0 / 5%)"
      }}
    >
      <div className="logo-container">
        
        {open ? (
          <>
            <img
              src="/Assets/img/AF_AEDAS_POSITIVO_RGB.svg"
              style={{ height: "70px" }}
              />
            <div style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
              <h1 className="appName">Porta</h1>
            </div>
          </>
        ) : (
          <img
            src="/Assets/img/LogoAedasSmall.svg"
            style={{ height: "70px", textAlign: "center" }}
          />
        )}
      </div>
      <div className="openCloseButton_container">
        <IconButton onClick={handleOpenClose} className="openCloseButton">
          {open ? (
            <ArrowBackIosIcon></ArrowBackIosIcon>
          ) : (
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          )}
        </IconButton>
      </div>

      <MainListItems
        setOpen={setIsOpen}
        open={open}
        menu={menu}
      ></MainListItems>

    </div>
  );
}

export default Header;
