import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";


import { getAllLinks } from '../../../Services/SideBar/SidebarServices';

import { LinkBox } from '../../Shared/LinkBox/LinkBox';

import './Footer.css';

export const Footer = (props: any) => {


	const [ links, setLinks ] = useState<any[]>([]);
  const [ footerLinks, setFooterLinks ] = useState<any[]>([]);
	const [ isLoading, setIsLoading ] = useState(true);

	
  
  useEffect(() => {
    let promises = [];

    promises.push(
        getAllLinks()
            .then((response: any) => {
              setLinks(response.data);
        })
    );

    // promises.push(
    //     getAllMedia()
    //         .then((response: any) => {
    //             setMedia(response.data);
    //      })
    // )

    // promises.push(
    //     getPosts()
    //         .then((response: any) => {
    //             setMedia(response.data);
    //      })
    // )

    Promise.all(promises)
        .then(() => setIsLoading(false))
        .catch((error) => {
        if (error.status.code === 404) {
            setIsLoading(false);
        } else {
            toast.error("Ha habido un error");
        }
    });


  }, [isLoading])

	useEffect(() => {
    //ToDo use name category on filter to avoid show this magic number

    setFooterLinks(
      links.filter( (link:any) => link?.acf?.categoria === 7)
    );

  }, [links])


  return (
    <>
   		{ props.links  
      ? <div className='section-box footer-links'>
        { footerLinks.map( (link: any, i: number) => <LinkBox link={link} className={'white-box'} key={i} tooltip={true}/>)}
      </div> 
      : <></> 
      }

      <div className='section-box footer-info'>
        <p>Cotización AEDAS (MSE): <strong>21.85€</strong> | Cambio: <strong>0.00€</strong> | Variación: <strong>0.00€</strong></p>
      </div>
    </>
  )
}
